@tailwind base;
@tailwind components;
@tailwind utilities;

.survey-input-control {
    @apply font-normal mt-2 bg-gray-50 border border-gray-300 text-gray-700 text-sm rounded focus:ring-gray-500 focus:border-gray-500 active:border-gray-500 hover:border-gray-400 focus:outline-none block w-full md:w-3/4 lg:w-1/2 p-2.5;
}

.survey-radio {
    @apply w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:outline-none active:outline-none;
}

.survey-trash-icon {
    @apply h-6 w-6 text-indigo-600 hover:text-indigo-800 duration-150 hover:scale-110;
}

.survey-next-btn {
    @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-2 rounded ml-2;
}

.survey-back-btn {
    @apply bg-gray-500 text-white font-bold py-2 px-4 rounded;
}

.survey-result-btn {
    @apply bg-green-600 hover:bg-green-800 text-white font-bold py-2 px-4 rounded ml-2;
}

.survey-result-btn-disabled {
    @apply bg-gray-500 text-white font-bold py-2 px-4 rounded ml-2 border border-gray-500;
}

.survey-ML-btn {
    @apply bg-slate-400 hover:bg-gray-800 text-white py-2 px-1 rounded sm:font-bold sm:text-xl sm:h-32 md:text-3xl md:h-32 lg:text-3xl lg:h-32;
}

.examination-result-table {
    @apply table-auto border-collapse border border-slate-500 text-center;
}

.examination-result-table th, .examination-result-table td {
    @apply border border-slate-600 px-2 py-1;
}

.examination-result-table th {
    @apply bg-gray-100;
}

.examination-result-table tr>td:first-child {
    @apply text-left;
}

.examination-section-table {
    @apply bg-white border border-gray-400 rounded-lg py-2 px-4 block w-full appearance-none leading-normal;
}

.animation-menu-button {
    @apply text-base font-medium text-gray-500 hover:text-black duration-150 hover:scale-110;
}

.animation-font-button {
    @apply font-bold py-2 hover:text-black duration-150 hover:scale-125;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted gray;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 250px;
    background-color: dimgray;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 20px;
    font-weight: normal;
    position: absolute;
    z-index: 99;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
}

.confirm-email {
    height: 40vh;
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.site-id {
    border: 8px solid red;
    background: white;
    font-size: 50px;
    position: absolute;
    margin-left: 5px;
    z-index: 100;
    width: 100px;
    text-align: center;
}

button:disabled,
button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}
